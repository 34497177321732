<template>

    <teleport to='#modal'>
        <div v-if="suspend_modal" class="bg-gray-800 bg-opacity-50 flex justify-center items-center absolute top-0 right-0 bottom-0 left-0 text-gray-600">
            <div :class="{ 'scale-out-center': leaving_modal }" class="scale-in-center bg-brown-100 px-16 py-10 rounded-md text-center">
                <h1 class="text-xl mb-4 font-bold text-slate-500">Do you want to suspend this user?</h1>
                <h2 class="text-xl mb-6 font-bold text-slate-500">{{ first_name }} {{ last_name }}</h2>
                <button @click="leaving_modal = true, turnOffModal()" class="bg-red-500 px-4 py-2 rounded-md text-md text-white">Cancel</button>
                <button @click="suspendUser(), leaving_modal = update" class="bg-brown-default px-7 py-2 ml-2 rounded-md text-md text-white font-semibold">Suspend</button>
            </div>
        </div>
    </teleport>
    <!-- End of suspend modal -->

    <teleport to='#modal'>
        <div v-if="unsuspend_modal" class="bg-gray-800 bg-opacity-50 flex justify-center items-center absolute top-0 right-0 bottom-0 left-0 text-gray-600">
            <div :class="{ 'scale-out-center': leaving_modal }" class="scale-in-center bg-brown-100 px-16 py-10 rounded-md text-center">
                <h1 class="text-xl mb-4 font-bold text-slate-500">Do you want to reactivate this user?</h1>
                <h2 class="text-xl mb-6 font-bold text-slate-500">{{ first_name }} {{ last_name }}</h2>
                <button @click="leaving_modal = true, turnOffUnsuspendModal()" class="bg-red-500 px-4 py-2 rounded-md text-md text-white">Cancel</button>
                <button @click="unsuspendUser(), leaving_modal = unsuspend" class="bg-brown-default px-7 py-2 ml-2 rounded-md text-md text-white font-semibold">Unsuspend</button>
            </div>
        </div>
    </teleport>
    <!-- End of unsuspend modal -->

    <teleport to='#modal'>
        <div v-if="delete_modal" class="bg-gray-800 bg-opacity-50 flex justify-center items-center absolute top-0 right-0 bottom-0 left-0 text-gray-600">
            <div :class="{ 'scale-out-center': leaving_modal }" class="scale-in-center bg-brown-100 px-16 py-10 rounded-md text-center">
                <h1 class="text-xl mb-4 font-bold text-slate-500">Do you want to delete this user?</h1>
                <h2 class="text-xl mb-6 font-bold text-slate-500">{{ first_name }} {{ last_name }}</h2>
                <button @click="leaving_modal = true, turnOffDeleteModal()" class="bg-red-500 px-4 py-2 rounded-md text-md text-white">Cancel</button>
                <button @click="deleteUser(), leaving_modal = deleted.data" class="bg-brown-default px-7 py-2 ml-2 rounded-md text-md text-white font-semibold">Delete</button>
            </div>
        </div>
    </teleport>
    <!-- End of delete modal -->

    <teleport to='#modal'>
        <div v-if="update_modal" class="bg-gray-800 bg-opacity-50 flex justify-center items-center absolute top-0 right-0 bottom-0 left-0 text-gray-600">
            <div :class="{ 'scale-out-center': leaving_modal }" class="scale-in-center bg-brown-100 px-4 py-5 rounded-md text-center">
                <form class="">
                    <div class="w-full bg-brown-200 py-1.5 rounded-t">
                        <p for="" class="w-full text-white">First Name</p>
                    </div>
                    <div class="gap-4">
                        <div class="form-group mb-6">
                            <input type="text" v-model="first_name" class="form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300
                            rounded-b transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-brown-100 focus:outline-none focus:ring-1 focus:ring-brown-100" aria-describedby="first_name">
                        </div>                                                        
                    </div>

                    <div class="w-full bg-brown-200 py-1.5 rounded-t">
                        <p for="" class="w-full text-white">Last Name</p>
                    </div>
                    <div class="grid gap-4">
                        <div class="form-group mb-6">
                            <input type="text" v-model="last_name" class="form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300
                            rounded-b transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-brown-100 focus:outline-none focus:ring-1 focus:ring-brown-100" aria-describedby="last_name">
                        </div>                                                        
                    </div>

                    <div class="w-full bg-brown-200 py-1.5 rounded-t">
                        <p for="" class="w-full text-white">Email</p>
                    </div>
                    <div class="grid gap-4">
                        <div class="form-group mb-6">
                            <input type="email" v-model="email" class="form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300
                            rounded-b transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-brown-100 focus:outline-none focus:ring-1 focus:ring-brown-100" aria-describedby="email">
                        </div>                                                        
                    </div>

                    <div class="w-full bg-brown-200 py-1.5 rounded-t">
                        <p for="" class="w-full text-white">Phone</p>
                    </div>
                    <div class="grid gap-4">
                        <div class="form-group mb-6">
                            <input type="text" v-model="phone" class="form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300
                            rounded-b transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-brown-100 focus:outline-none focus:ring-1 focus:ring-brown-100" aria-describedby="phone">
                        </div>                                                        
                    </div>                        
                </form>
                <button @click="leaving_modal = true, turnOffUpdateModal()" class="bg-red-500 px-4 py-2 rounded-md text-md text-white">Cancel</button>
                <button @click="modifyUser()" class="bg-brown-default px-7 py-2 ml-2 rounded-md text-md text-white font-semibold">Modify</button>
            </div>
        </div>
    </teleport>
    <!-- End of update user modal -->
    
    <div class="p-4">
        <top-search></top-search>

        <div class="w-full flex justify-between my-4 p-4">
            <label for="users" class="font-semibold">Users</label>
            <div class="flex justify-between">
                <button class="px-5 py-2 rounded-lg hover:outline">
                    Export
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 inline" viewBox="0 0 20 20" fill="currentColor">
                        <path fill-rule="evenodd" d="M3 17a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zm3.293-7.707a1 1 0 011.414 0L9 10.586V3a1 1 0 112 0v7.586l1.293-1.293a1 1 0 111.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z" clip-rule="evenodd" />
                    </svg>
                </button>
                <button class="ml-4 bg-brown-default text-gray-50 px-5 py-2 rounded-lg">Add User</button>
            </div>
        </div>

        <div class="w-full p-5">
            <div class="bg-white flex justify-between p-4">
                <div>
                    <input type="text" placeholder="Search" v-model="search" class="ml-2 outline-none py-1 px-2 text-md border-2 rounded-md">
                </div>
                
                <div>
                    <input type="date" class="ml-2 outline-none py-1 px-2 text-md border-2 rounded-md">
                </div>
            </div>
        </div>

        <div v-if="!user_loader" class="w-full mb-12 px-4">
            <div class="flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded">
                <!-- <div class="rounded-t mb-0 px-4 py-3 border-0">
                    <div class="flex flex-wrap items-center">
                        <div class="relative w-full px-4 max-w-full flex-grow flex-1 ">
                            <h3 class="font-semibold text-lg">Users</h3>
                        </div>
                    </div>
                </div> -->
                <div class="block w-full overflow-x-auto">
                    <table class="table-auto items-center w-full bg-transparent border-collapse">
                        <thead class="">
                            <tr>
                                <th class="px-4 align-bottom py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-pink-50">#</th>
                                <th class="px-6 align-bottom py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-pink-50">Name</th>
                                <th class="px-6 align-bottom py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-pink-50">Phone</th>
                                <th class="px-6 align-bottom py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-pink-50">Email</th>
                                <th class="px-6 align-bottom py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-pink-50">State</th>
                                <th class="px-6 align-bottom py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-pink-50">Date Updated</th>
                                <th class="px-6 align-bottom py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-pink-50">Role</th>
                                <th class="px-6 align-bottom py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-pink-50">Status</th>
                                <th class="px-6 align-bottom py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-pink-50">Actions</th>
                            </tr>
                        </thead>

                        <tbody v-if="users" class="overflow-y-auto transition duration-700 ease-in-out">
                            <tr class="bg-white border-b-2" v-for="(user, index) in filteredUsers" :key="index">
                                <td class="border-t-0 rounded-md pl-4 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left flex items-center">
                                    <span class="font-bold"> {{ index + 1 }} </span>
                                </td>
                                <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left">{{ user.first_name }} {{ user.last_name }} </td>
                                <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left">{{ user.phone }}</td>
                                <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left">{{ user.email }}</td>
                                <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left" v-if="user.state">{{ user.state.name }}</td>
                                <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left" v-else> --- </td>
                                <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left">{{ moment(user.updated_at).format('DD-MMM-YYYY') }}</td>
                                <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left uppercase">{{ user.role }}</td>
                                <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left uppercase">{{ user.status }}</td>
                                <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left">
                                    <!-- <button @click="action = user.id" class="font-bold tracking-wider">...</button> -->
                                    
                                    <div class="flex justify-center">
                                        <div>
                                            <div class="dropstart relative">
                                                <button @click="user_id = user.id, first_name = user.first_name, last_name = user.last_name, email = user.email, phone = user.phone" data-mdb-ripple="true" data-mdb-ripple-color="light"
                                                    class="dropdown-toggle text-brown-default font-bold text-lg uppercase focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg active:text-white transition duration-150 ease-in-out flex items-center whitespace-nowrap"
                                                    type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">...
                                                </button>
                                                <ul class="dropdown-menu min-w-max absolute bg-white text-base z-50 float-left py-2 list-none text-left
                                                    rounded-lg shadow-lg mt-1 hidden m-0 bg-clip-padding border-none" aria-labelledby="dropdownMenuButton1">
                                                    <!-- <li data-bs-toggle="modal" data-bs-target="#exampleModal"> -->
                                                    <li @click="user = user, leaving_modal = false, update_modal = true">
                                                        <button class="dropdown-item text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-gray-700 hover:bg-gray-100">
                                                            Edit
                                                        </button>
                                                    </li>
                                                    <li @click="first_name = user.first_name, last_name = user.last_name, leaving_modal = false, suspend_modal = true" class="">
                                                        <button class="dropdown-item text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-gray-700 hover:bg-gray-100">
                                                            Suspend
                                                        </button>
                                                    </li>
                                                    <li @click="first_name = user.first_name, last_name = user.last_name, leaving_modal = false, unsuspend_modal = true" class="">
                                                        <button class="dropdown-item text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-gray-700 hover:bg-gray-100">
                                                            Unsuspend
                                                        </button>
                                                    </li>
                                                    <li @click="first_name = user.first_name, last_name = user.last_name, leaving_modal = false, delete_modal = true" class="">
                                                        <button class="dropdown-item text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-gray-700 hover:bg-gray-100">
                                                            Delete
                                                        </button>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </td>
                                    
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>

        <square-grid v-else class="absolute top-1/2 left-1/2"></square-grid>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import moment from 'moment'
import TopSearch from '@/components/TopSearch.vue'
import SquareGrid from '@/components/loaders/SquareGrid.vue'
import Swal from 'sweetalert2'

const Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
})
export default {
    name: 'User',
    components: {
        TopSearch,
        SquareGrid,
    },
    data() {
        return {
            moment: moment,
            action: "",
            user: "",
            user_id: "",
            first_name: "",
            last_name: "",
            email: "",
            phone: "",
            search: "",
            update_modal: false,
            suspend_modal: false,
            unsuspend_modal: false,
            delete_modal: false,
            leaving_modal: false,
        }
    },
    computed: {
        ...mapGetters({
            users: 'usersModule/getUsers',
            user_loader: 'usersModule/getUserLoader',
            update: 'usersModule/getUpdate',
            suspend: 'usersModule/getSuspend',
            unsuspend: 'usersModule/getUnsuspend',
            deleted: 'usersModule/getDeleted',
        }),

        filteredUsers () {
            return this.users.filter(user => {
                return user.first_name.toLowerCase().indexOf(this.search.toLowerCase()) > -1 || 
                    user.last_name.toLowerCase().indexOf(this.search.toLowerCase()) > -1 ||
                    user.email.toLowerCase().indexOf(this.search.toLowerCase()) > -1 ||
                    user.phone.toLowerCase().indexOf(this.search.toLowerCase()) > -1 ||
                    user.state.name.toLowerCase().indexOf(this.search.toLowerCase()) > -1 ||
                    user.role.toLowerCase().indexOf(this.search.toLowerCase()) > -1 ||
                    user.created_at.toLowerCase().indexOf(this.search.toLowerCase()) > -1
            })
        },

        suspendedStatus () {
            return this.suspend ? 
                Toast.fire({
                    icon: 'success',
                    title: 'User has been suspended!',
                }) : ''
        }
    },
    methods: {
        closeDropdown() {
            this.action = ''
        },

        modifyUser() {
            const data = { user_id: this.user_id, first_name: this.first_name, last_name: this.last_name, email: this.email, phone: this.phone }
            this.$store.dispatch('usersModule/updateUsersRequest', data)
            .then(() => { 
                if (this.update.status == 'success') {
                    Toast.fire({
                        icon: 'success',
                        title: this.update.message,
                    })
                    this.turnOffUpdateModal()
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: "Unabled to update..."
                    })
                    
                }
            })
            .catch(() => {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: "Unabled to log you in..."
                })
                
            })
        },

        suspendUser() {
            this.$store.dispatch('usersModule/suspendUserRequest', this.user_id)
            .then(() => {
                if (this.suspend.status == 'success') {
                    Toast.fire({
                        icon: 'success',
                        title: 'User suspension is processing...',
                    })
                    
                    this.turnOffModal()
                } 
            })
            .catch(() => {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: "An error occurred..."
                })
            })           
        },

        unsuspendUser() {
            this.$store.dispatch('usersModule/unsuspendUserRequest', this.user_id)
            .then(() => {
                if (this.unsuspend.status == 'success') {
                    Toast.fire({
                        icon: 'success',
                        title: this.unsuspend.message,
                    })
                    this.turnOffUnsuspendModal()
                }                 
            })
            .catch(() => {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: "An error occurred..."
                })
            })           
        },

        deleteUser() {
            this.$store.dispatch('usersModule/deleteUserRequest', this.user_id)
            .then(() => {
                if (this.deleted.status == 'success') {
                    Toast.fire({
                        icon: 'success',
                        title: this.deleted.message,
                    })
                    this.turnOffDeleteModal()
                }                 
            })
            .catch(() => {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: "An error occurred..."
                })
            })           
        },

        turnOffModal() {
            setTimeout(() => {
                this.suspend_modal = false;
            }, 500)
            
        },

        turnOffUpdateModal() {
            setTimeout(() => {
                this.update_modal = false;
            }, 500)
            
        },

        turnOffUnsuspendModal() {
            setTimeout(() => {
                this.unsuspend_modal = false;
            }, 500)
            
        },

        turnOffDeleteModal() {
            setTimeout(() => {
                this.delete_modal = false;
            }, 500)
            
        },

    }
}
</script>

<style scoped>
    .scale-in-center {
        -webkit-animation: scale-in-center 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
        animation: scale-in-center 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    }

    @-webkit-keyframes scale-in-center {
        0% {
            -webkit-transform: scale(0);
            transform: scale(0);
            opacity: 1;
        }
        100% {
            -webkit-transform: scale(1);
            transform: scale(1);
            opacity: 1;
        }
    }

    @keyframes scale-in-center {
        0% {
            -webkit-transform: scale(0);
            transform: scale(0);
            opacity: 1;
        }
        100% {
            -webkit-transform: scale(1);
            transform: scale(1);
            opacity: 1;
        }
    }

    .scale-out-center {
        -webkit-animation: scale-out-center 0.5s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
        animation: scale-out-center 0.5s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
    }

    @-webkit-keyframes scale-out-center {
        0% {
            -webkit-transform: scale(1);
            transform: scale(1);
            opacity: 1;
        }
        100% {
            -webkit-transform: scale(0);
            transform: scale(0);
            opacity: 1;
        }
    }

    @keyframes scale-out-center {
        0% {
            -webkit-transform: scale(1);
            transform: scale(1);
            opacity: 1;
        }
        100% {
            -webkit-transform: scale(0);
            transform: scale(0);
            opacity: 1;
        }
    }


</style>
